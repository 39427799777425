<template>
    <div class="d-block d-lg-none">
        <div class="row">
            <div class="col-10 col-sm-9 mb-0 col-lg-10">
                <p class="groupsMobile" style="margin-left: 9px;">Communities and groups</p>
            </div>
            <div class="col-2 col-sm-3 col-lg-2">
                <p class="closeButton" @click="GoSetting()"><font-awesome-icon :icon="['fas','times']" /></p>
            </div>
        </div>
        <div class="hr-border"></div>
    </div>
    <div class="mobileStylePublic">
        <p class="group-para mb-0">Based on your interests and/or the consultations you have availed, we have enrolled you into
            relevant communities and patient support groups. Feel free to deselect those groups that do not interest you.
        </p>
        <fieldset class="curve-box">
            <legend class="subHeadingText">Doctor communities</legend>
            <div class="row">
                <div class="col-12 col-lg-4 col-sm-6 col-md-6 mt-0" v-for="doctor in doctorInfo" :key="doctor">
                <div class="border-right-color pe-4">
                <ul>
                <li class="list-style-none ">
                <div class="row">
                <div class="col-10 mb-0 col-lg-8">
                <span class=''>{{ doctor.firstName }} {{ doctor.lastName}}</span>
                </div>

                <div class="col-2 col-lg-4">
                <span class='text-red-color'  >
                <font-awesome-icon :icon="['fas', 'times-circle']"  @click="showPopup(doctor)"/>
                </span>

                <div v-if="isPopupVisible" class="popup">
      <div class="popup-content">
        <p>Do you want to Opt out of Community?</p>
        <div style="display: flex; justify-content: space-around;">
          <button @click="deleteDoctorCommunity()" class="yes-button">Yes</button>
          <button @click="handleNo" class="no-button">No</button>
        </div>
      </div>
    </div>
      </div>
        </div>
        </li>
        </ul>
        </div>
                    </div>
                </div>
                </fieldset>
        <div class="hr-border d-none d-lg-block"></div>
        <fieldset class="curve-box">
            <legend class="subHeadingText subtextstyle">Patient support groups</legend>
           
            <div class="row">
                <div class="col-12 col-lg-4 col-sm-6 col-md-6 mt-0" v-for="patientGroup in publicPatientgroup" :key="patientGroup">
            <div class="border-right-color pe-4">
            <div>
            <ul>
            <li class="list-style-none ">
                <div class="row" >
                <div class="col-10 mb-0 col-lg-8">
                <span class=''>{{ patientGroup.groupname }}</span>
                </div>
                <div class="col-2 col-lg-4">
                <span class='text-red-color'  >
                <font-awesome-icon :icon="['fas', 'times-circle']" @click="showPopupGroup(patientGroup)" />
                </span>

                <div v-if="isPopupGroupVisible" class="popup">
      <div class="popup-content">
        <p>Do you want to opt out of community?</p>
        <div style="display: flex;justify-content: space-around;">
          <button @click="deletePatientGroup()" class="yes-button">Yes</button>
          <button @click="handleNoGroup" class="no-button">No</button>
        </div>
      </div>
    </div>


                    </div>
                </div>
            </li>
        </ul>
    </div>
    </div>
    </div>
            </div>
        </fieldset>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    components: {},
    data() {
        return {
            isPopupVisible: false,
            isPopupGroupVisible: false,
            userDoctor: {},
            publicPatientgroup:[],
            doctorInfo:[],
            doctorData:null,
            patientGroupData:null
        };
    },
    created: function () {
        this.tagId = this.$route.query.id;
        this.getPublic();
    },
    methods: {

        showPopup(doctor) {
      this.doctorData=doctor
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
    },

    handleNo() {
      this.closePopup();
    },


    showPopupGroup(patient) {
      this.patientGroupData=patient
      this.isPopupGroupVisible = true;
    },
    closePopupGroup() {
      this.isPopupGroupVisible = false;
    },

    handleNoGroup() {
      this.closePopupGroup();
    },
        async getPublic() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            let publicsData = localStorage.getItem('publicSignUpData')

            this.publicUserIds = JSON.parse(publicsData)
            axios
                .get(`${BASE_API_URL}/public-user/${this.publicUserIds}/one`)
                .then((response) => {
                    this.userDoctor = response.data
                    this.publicPatientgroup = this.userDoctor.publicPatientId
                    this.doctorInfo=this.userDoctor.doctorsID
                })
        },
        GoSetting() {
            this.$router.push({ path: `/user/mobile-setting` });

        },
        deletePatientGroup() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .delete(`${BASE_API_URL}/publicpatientgroup/${this.patientGroupData.publicpatientgroupid}/delete`)
        .then((response) => {
        if(response.data){
          this.patientGroupData=null
          this.getPublic();
          this.closePopupGroup();
        }

        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    deleteDoctorCommunity() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .delete(`${BASE_API_URL}/publicdoctorcommunity/${this.doctorData.publicdoctorcommunityid}/delete`)
        .then((response) => {
        if(response.data){
          this.doctorData=null
          this.getPublic();
          this.closePopup();
        }

        })
        .catch(function (error) {
          console.log(error.response);
        });
    },

    }
}
</script>
<style>

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.yes-button,
.no-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.yes-button {
  background-color: #38761D;
  color: #fff;
}

.no-button {
  background-color: #FF9900;
  color: #fff;
}

.text-red-color{
  font-size:20px;
  color: #cd381c;
  cursor: pointer;
}
.subtextstyle {
    margin-top: 16px;
}

.list-style-none {
    list-style: none !important;
    line-height: 1.5em;
}

svg.svg-inline--fa.fa-check-circle.fa-w-16 {
    color: lightgreen;
}

@media screen and (max-width:991px) {
    .group-para {
        margin-top: 15px;
    }

    .mobileStylePublic {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 6rem;
    }
    fieldset.curve-box {
        border: 2px solid #00979e;
        border-radius: 20px;
        padding: 15px;
        margin-top: 1.5rem;
    }

    legend.subHeadingText {
        background: #fff;
        padding: 4px 10px;
        /* margin: 0 auto; */
        display: block;
        float: none;
        width: auto;
        font-size: 20px;
    }

    .groupsMobile {
        margin-left: 20px;
        /* margin-bottom: 10px; */
        margin-top: 1rem;
        color: #000;
        font-weight: 550;
        font-size: 21px;
    }
}
</style>